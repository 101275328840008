
import { mapState, mapGetters } from 'vuex'
import TopBanner from '~/components/top-banner/TopBanner'
import YotpoController from '~/components/yotpo/YotpoController'
import RichTextSchema from '~/components/rich-text-schema/RichTextSchema'
import CustomActionController from '~/components/custom-action/CustomActionController'
import initYotpoLoyaltyWidgets from '~/mixins/yotpo/initYotpoLoyaltyWidgets'
import sentryUserMixin from '~/mixins/sentry-user-mixin'

export default {
  name: 'DefaultLayout',
  components: {
    TopBanner,
    YotpoController,
    FilterAccordionBlock: () => import('~/components/accordion-block/FilterAccordionBlock'),
    RichTextSchema,
    CustomActionController,
    Header: () => import('~/components/header/Header'),
  },
  mixins: [
    initYotpoLoyaltyWidgets,
    sentryUserMixin,
  ],
  data () {
    return {
      showCookieAfterTimeout: false,
      isMobile: false,
    }
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.currentLanguageCode
      },
      link: this.canonicalHreflang,
      script: [
        this.hasReferalLink ? this.yotpoHeadScript : '',
      ]
    }
  },
  computed: {
    ...mapState({
      cartSidebarOpen: state => state.ui.miniCartOpen,
      cartPopupOpen: state => state.ui.cartPopupOpen,
      mobileSidebarOpen: state => state.ui.mobileSidebarOpen,
      cartPopupOpenMobile: state => state.ui.cartPopupOpenMobile,
      cartPopupOpenDesktop: state => state.ui.cartPopupOpenDesktop,
      overlayIndex: state => state.ui.overlayIndex,
      pages: state => state.storyblok.stories,
      filterAccordionBlockData: state => state.ui.filterAccordionBlockData,
      filterAccordionBlockOpen: state => state.ui.filterAccordionBlockOpen,
      sizeSelectorMobileOpen: state => state.ui.sizeSelectorMobileOpen,
      sizeSelectorDesktopOpen: state => state.ui.sizeSelectorDesktopOpen,
      customisationPopupOpen: state => state.ui.customisationPopupOpen,
      currentProduct: state => state.ui.currentProduct,
      selectedProductID: state => state.ui.selectedProductID,
      isProductDiscontinued: state => state.ui.isProductDiscontinued,
      productItems: state => state.ui.productItems,
      addedProduct: state => state.ui.addedProduct,
      addedPrimarySize: state => state.ui.addedPrimarySize,
      addedSecondarySize: state => state.ui.addedSecondarySize,
      showWashingInstructions: state => state.ui.washingInstructions,
      showSizeGuide: state => state.ui.sizeGuide,
      productType: state => state.ui.productType,
      productReviewsSidebarOpen: state => state.ui.productReviewsSidebarOpen,
      productQuestionsSidebarOpen: state => state.ui.productQuestionsSidebarOpen,
      countrySelectorPopup: state => state.ui.countrySelectorPopup,
    }),
    ...mapGetters({
      market: 'frontend/market',
      marketsList: 'frontend/marketsList',
      cart: 'centra-cart/cart',
      getBanners: 'missmary-storyblok/getBanners',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentMarketId: 'frontend/currentMarketId',
      currentCountryCode: 'frontend/currentCountryCode',
      countriesSorted: 'frontend/countriesSorted',
      getWashingInstructions: 'missmary-storyblok/getWashingInstructions',
      getSizeGuide: 'missmary-storyblok/getSizeGuide',
      countrySpecificInformation: 'storyblok/getCountrySpecificInformation',
      currentPage: 'routes/currentPage',
      getGbFeatureValue: 'growthbook/getGbFeatureValue',
    }),
    canonical () {
      // TODO: Remove this when we have a proper solution for missmary.no
      let marketName = this.market.name
      if (marketName === 'missmary.no') {
        marketName = 'missmary.com'
      }
      return 'https://www.' + marketName + this.$route.path
    },
    canonicalHreflang () {
      const marketArray = []
      marketArray.push({ hid: 'canonical', rel: 'canonical', href: this.canonical })
      for (const market of this.countriesSorted) {
        const mark = {
          hid: 'alternate',
          rel: 'alternate',
          href: 'https://www.' + this.getMarketDomain(this.marketsList, market.market) + '/' + market.country.toLowerCase() + this.$route.path.substring(3),
          hreflang: market.language + '-' + market.country,
        }
        // TODO: Remove this when we have a proper solution for missmary.no
        if (mark.href.includes('missmary.no')) {
          mark.href = mark.href.replace('missmary.no', 'missmary.com')
        }
        marketArray.push(mark)
      }
      return marketArray
    },
    disableRotatingBanners () {
      return this.getGbFeatureValue('banners:disable-rotating-top-banner')
    },
    banners () {
      const bannerData = this.getBanners(this.currentLanguageCode, this.currentMarketId)?.data
      if (this.disableRotatingBanners) {
        return bannerData.flatMap(banner => banner?.banners ? banner.banners : banner)
      }
      return bannerData
    },
    /**
     * Get the current page data so we can get things that are
     * not in the page content (Settings, Header Hero, SEO etc.)
     */
    headerPosition () {
      return this.currentPage?.headerPosition ? 'header-' + this.currentPage?.headerPosition : ''
    },
    showBreadcrumbs () {
      return this.currentPage?.breadcrumbs
    },
    washingInstructions () {
      return this.getWashingInstructions(this.currentLanguageCode, this.currentMarketId)?.data
    },
    sizeGuide () {
      return this.getSizeGuide(this.currentLanguageCode, this.currentCountryCode)?.data.filter(function (guide) {
        return guide.component === 'SizeGuide'
      })
    },
    breadCrumbs () {
      return [
        {
          position: 1,
          name: this.$t('Global_Shop'),
          url: this.$u('')
        },
        {
          position: 2,
          name: this.currentPage.seo.title,
          url: ''
        }
      ]
    },
    hasReferalLink () {
      return this.$nuxt.$route.fullPath.includes('referral?sref_id=')
    }
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        if (process.client) {
          window._growthbook?.setURL?.(window.location.href)
        }
      },
    }
  },
  mounted () {
    // load cookiebot script
    let language = this.currentLanguageCode
    if (language === 'no') {
      language = 'nb'
    }
    const cookieBotScript = document.createElement('script')
    cookieBotScript.id = 'Cookiebot'
    cookieBotScript.src = 'https://consent.cookiebot.com/uc.js'
    cookieBotScript.setAttribute('data-culture', language)
    cookieBotScript.setAttribute('data-cbid', this.$config.cookiebotId)
    cookieBotScript.setAttribute('data-blockingmode', 'auto')
    cookieBotScript.setAttribute('data-consentmode-defaults', 'disabled')
    document.body.appendChild(cookieBotScript)

    // Listen for Cookiebot consent event
    window.addEventListener('CookiebotOnAccept', this.preloadScripts)

    this.showNotification()
    if (this.$config.runIPStackFunction) {
      this.ipStackRedirect()
    }

    if (this.$route.fullPath.includes('help-desk')) {
      window.location.href = 'https://support.missmary.com/hc/'
    }

    window.addEventListener('scroll', this.setInteracted, { passive: true })
    window.addEventListener('touchstart', this.setInteracted, { passive: true })
    window.addEventListener('mouseup', this.setInteracted, { passive: true })
    window.addEventListener('mouseover', this.setInteracted, { passive: true })

    this.$store.dispatch('missmary-storyblok/fetchSizeGuide')

    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  },
  unmounted () {
    window.removeEventListener('scroll', this.setInteracted, { passive: true })
    window.removeEventListener('touchstart', this.setInteracted, { passive: true })
    window.removeEventListener('mouseup', this.setInteracted, { passive: true })
    window.removeEventListener('mouseover', this.setInteracted, { passive: true })
    window.removeEventListener('CookiebotOnAccept', this.preloadScripts)
    window.removeEventListener('resize', this.checkIfMobile)
  },
  methods: {
    setInteracted () {
      window.removeEventListener('scroll', this.setInteracted, { passive: true })
      window.removeEventListener('touchstart', this.setInteracted, { passive: true })
      window.removeEventListener('mouseup', this.setInteracted, { passive: true })
      window.removeEventListener('mouseover', this.setInteracted, { passive: true })
      this.showCookieAfterTimeout = true
      if (!window.Worker && !/\/checkout/.test(this.$route.fullPath)) {
        this.$store.dispatch('centra-cart/initialize', this.$route)
      }
    },
    /**
     * do a client side lookup on ipstack and lowercase compare the
     * country code returned by ipstack with geoipRedirectCode. if they
     * do not match, we redirect to the ipstack one with ?ic=THEIPSTACKCOUNTRY
     */
    ipStackRedirect () {
      const geoipRedirectCode = this.$route.query.rc
      const isIpStackRedirected = !!this.$route.query.ic
      const ipStackAccessKey = this.$config.ipStackAccessKey
      if (
        window?.location &&
        /missmary\.com$/.test(window?.location?.host) &&
        geoipRedirectCode &&
        ipStackAccessKey &&
        !isIpStackRedirected
      ) {
        this.$axios.get(`https://api.ipstack.com/check?access_key=${ipStackAccessKey}`)
          .then(
            (response) => {
              const ipStackCountryCode = response?.data?.country_code
              if (ipStackCountryCode && ipStackCountryCode.toLowerCase() !== geoipRedirectCode.toLowerCase()) {
                const regexp = new RegExp(`^/${this.currentCountryCode.toLowerCase()}[/|$]`)
                const redirectPath = window.location.pathname.replace(regexp, `/${ipStackCountryCode.toLowerCase()}/`)
                const urlParams = new URLSearchParams(window.location.search)
                urlParams.delete('rc')
                urlParams.append('ic', ipStackCountryCode.toLowerCase())
                window.location.href = `${redirectPath}?${urlParams}`
              }
            })
          .catch((error) => {
            console.error('IP-stack error', error)
          })
      }
    },
    checkIfMobile () {
      this.isMobile = window.innerWidth < 768
    },
    closeModal () {
      this.$store.dispatch('ui/closeAll')
    },
    getMarketDomain (marketsList, value) {
      return marketsList.find(market => market.market === value).name
    },
    showNotification () {
      const query = Object.assign({}, this.$route.query)
      if (query.n) {
        this.$nextTick(() => {
          this.$notify({
            text: this.$t(query.n),
            type: 'success',
            duration: 10000,
          })

          // Clear the query param
          delete query.n
          this.$router.replace({ query })
        })
      }
    },
    preloadScripts () {
      const preConnectScripts = {
        statistics: [
          'https://static.klaviyo.com',
          'https://fast.a.klaviyo.com',
          'https://t.myvisitors.se',
          'https://analytics.tiktok.com',
        ],
        marketing: [
          'https://bat.bing.com',
          'https://convapi.missmary.com',
          'https://connect.facebook.net',
          'https://s.pinimg.com'
        ],
        any: [
          'https://www.google-analytics.com'
        ]
      }
      const preloadScripts = {
        statistics: [
          'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VdUMHL',
          'https://static.redeal.se/widget/js/redeal.js'
        ],
        marketing: [
          'https://s.pinimg.com/ct/core.js',
          'https://bat.bing.com/bat.js',
          'https://convapi.missmary.com/sdk/capig-events.js',
          'https://connect.facebook.net/en_US/fbevents.js'
        ],
        any: [
          'https://www.google-analytics.com/analytics.js'
        ]
      }

      let scriptElement

      if (Cookiebot.consented && (Cookiebot.consent.marketing || Cookiebot.consent.statistics)) {
        Object.entries(preConnectScripts).forEach(([key, scripts]) => {
          if (key === 'any' || Cookiebot.consent[key]) {
            scripts.forEach((href) => {
              scriptElement = document.createElement('link')
              scriptElement.setAttribute('rel', 'preconnect')
              scriptElement.setAttribute('href', href)
              document.head.appendChild(scriptElement)
            })
          }
        })

        Object.entries(preloadScripts).forEach(([key, scripts]) => {
          if (key === 'any' || Cookiebot.consent[key]) {
            scripts.forEach((href) => {
              scriptElement = document.createElement('link')
              scriptElement.setAttribute('rel', 'preload')
              scriptElement.setAttribute('as', 'script')
              scriptElement.setAttribute('href', href)
              document.head.appendChild(scriptElement)
            })
          }
        })

        this.$googleAnalyticsToServer.send()
      }
    }
  }
}
