import { render, staticRenderFns } from "./TopBanner.vue?vue&type=template&id=834ee3f6&scoped=true"
import script from "./TopBanner.vue?vue&type=script&lang=js"
export * from "./TopBanner.vue?vue&type=script&lang=js"
import style0 from "./TopBanner.vue?vue&type=style&index=0&id=834ee3f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "834ee3f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MpLink: require('/usr/src/app/components/mp-link/MpLink.vue').default,TextBlock: require('/usr/src/app/components/text-block/TextBlock.vue').default,PaymentGatewayLogos: require('/usr/src/app/components/payment-gateway-logos/PaymentGatewayLogos.vue').default,CountrySelectorIcon: require('/usr/src/app/components/country-selector/CountrySelectorIcon.vue').default})
